import {testIdProps} from '@github-ui/test-id-props'
import {PlusIcon} from '@primer/octicons-react'
import {ActionList, ActionMenu, Button} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {forwardRef, useState} from 'react'

import type {FilterProvider} from '../types'
import styles from './AddFilterButton.module.css'

interface AddFilterButtonProps {
  size: 'small' | 'medium' | 'large'
  filterProviders: FilterProvider[]
  addNewFilterBlock: (provider: FilterProvider) => void
}
export const AddFilterButton = forwardRef<HTMLButtonElement, AddFilterButtonProps>(
  ({size, filterProviders, addNewFilterBlock}, ref) => {
    const [addFilterMenuOpen, setAddFilterMenuOpen] = useState(false)

    return (
      <>
        <Button
          size={size}
          ref={ref as React.RefObject<HTMLButtonElement>}
          onClick={() => setAddFilterMenuOpen(!addFilterMenuOpen)}
          {...testIdProps('afd-add-filter')}
        >
          <Octicon icon={PlusIcon} className={styles.Octicon_0} />
          Add a filter
        </Button>
        <ActionMenu
          anchorRef={ref as React.RefObject<HTMLButtonElement>}
          open={addFilterMenuOpen}
          onOpenChange={setAddFilterMenuOpen}
        >
          <ActionMenu.Overlay className={styles.ActionMenu_Overlay_0}>
            <ActionList
              {...testIdProps('afd-add-filter-options')}
              aria-label="Filter options"
              className={styles.ActionList_0}
            >
              {filterProviders.map(provider => {
                const {icon: Icon} = provider
                return (
                  <ActionList.Item
                    key={`advanced-filter-add-provider-${provider.key}`}
                    onSelect={() => addNewFilterBlock(provider)}
                    role="menuitem"
                    className={styles.ActionList_Item_0}
                  >
                    {Icon && (
                      <ActionList.LeadingVisual>
                        <Icon />
                      </ActionList.LeadingVisual>
                    )}
                    {provider.displayName ?? provider.key}
                  </ActionList.Item>
                )
              })}
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </>
    )
  },
)

AddFilterButton.displayName = 'AddFilterButton'
